import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import JobSelect from '@app/src/Components/Common/JobSelect/JobSelect';
import { setHolisticOnboardingJob } from '@app/src/actions/holisticOnboardingActions';
import { jobsSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { jobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';

const OnboardingJobSelect = () => {
  const dispatch = useDispatch();

  const allJobs = useSelector(jobCategoriesSelector);
  const selectedJobs = useSelector(jobsSelector);

  const handleAddJob = (job) => {
    dispatch(setHolisticOnboardingJob([_.find(allJobs, { slug: job.slug }), ...selectedJobs]));
  };

  const handleRemoveJob = (job) => {
    dispatch(setHolisticOnboardingJob(selectedJobs.filter((selectedJob) => selectedJob.slug !== job.slug)));
  };

  return (
    <JobSelect
      onAdd={handleAddJob}
      onRemove={handleRemoveJob}
      selectedJobs={selectedJobs}
      origin='holistic onboarding'
    />
  );
};

export default OnboardingJobSelect;

import React from 'react';
import Slide from '@mui/material/Slide';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { initialLoadSelector } from '@app/src/selectors/holisticOnboardingSelectors';

const PageTransition = ({ children, pathname }) => {
  const { action, location } = useHistory();

  const loading = useSelector(initialLoadSelector);

  const noTransitionPaths = ['/h/onboarding/situations'];

  return loading || noTransitionPaths.includes(pathname) ? (
    <div>{children}</div>
  ) : (
    <Slide key={location.pathname} in timeout={300} direction={action !== 'POP' ? 'left' : 'right'}>
      {children}
    </Slide>
  );
};

export default PageTransition;

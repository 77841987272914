import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { ArrowLeft } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bankLinkPendingSelector } from '@app/src/selectors/bankSelectors';
import {
  isPreviousSelector,
  loadingSelector,
  skippableSelector,
  validationSelector
} from '@app/src/selectors/holisticOnboardingSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';

const OnboardingFooter = ({ pathname }) => {
  const dispatch = useDispatch();

  const { push, goBack } = useHistory();

  const loading = useSelector(loadingSelector);
  const bankLinkLoading = useSelector(bankLinkPendingSelector);
  const skippable = useSelector((state) => skippableSelector(state, { pathname }));
  const isValid = useSelector((state) => validationSelector(state, { pathname }));
  const isPrevious = useSelector((state) => isPreviousSelector(state, pathname));

  const hiddenPaths = ['/h/onboarding/loading-initial', '/h/onboarding/app-download'];

  const handleContinue = () => {
    dispatch(onContinue({ pathname, push }));
  };

  const handleGoBack = () => {
    goBack();
  };

  return hiddenPaths.includes(pathname) ? null : (
    <div className='h-onboarding-footer'>
      <div className='h-onboarding-footer-content'>
        <Stack spacing={1} direction='row' justifyContent='flex-end'>
          {isPrevious && (
            <Button variant='outlined' color='primary' onClick={handleGoBack}>
              <ArrowLeft />
            </Button>
          )}
          <LoadingButton
            variant={skippable ? 'outlined' : 'contained'}
            color='primary'
            disabled={(!skippable && !isValid) || loading || bankLinkLoading}
            disableRipple={skippable}
            loading={loading || bankLinkLoading}
            onClick={handleContinue}
            sx={{ minWidth: '90px' }}
            disableElevation
          >
            {skippable ? 'Skip' : 'Next'}
          </LoadingButton>
        </Stack>
      </div>
    </div>
  );
};

export default OnboardingFooter;

import _ from 'lodash';
import { baseApi, TAGS } from '@app/src//api/baseApi';
import { setBuild } from '@app/src/actions/workActions';
import plaidApi from '@app/src/api/plaidApi';
import history from '@app/src/keeperHistory';
import store from '@app/src/store/store';
import { PATH_COMPONENT__SUBMIT_EMAIL_INFO } from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { getTaxFilingPaid } from '@app/src/taxflow/sections/submit/services/submitService';
import { setAppOs } from '@app/src/taxflow/shared/actions/sharedActions';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';

const sentMsgToWeb = (type, data) => async (dispatch, getState) => {
  if (type === 'set_build') {
    dispatch(setBuild(data));
  } else if (type === 'refetch_paid_status') {
    await dispatch(plaidApi.util.invalidateTags([TAGS.PAID, TAGS.ACCOUNT_DETAILS]));
    const paid = await dispatch(getTaxFilingPaid());
    const question = currentQuestionSelector(getState());
    // If user paid and in the submit flow, send to the submit email info screen.
    // Else, the user paid outside of the submit flow (via a modal or prompt), persist them where they were.
    if (paid && /submit/.test(question.slug)) {
      history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_EMAIL_INFO}`);
    }
  } else if (type === 'app_os') {
    dispatch(setAppOs(data));
  } else if (type === 'back_button_press') {
    history.goBack();
  } else if (type === 'reset_api_state') {
    dispatch(baseApi.util.invalidateTags(_.values(TAGS)));
  }
};

window.sentMsgToWeb = async (type, data) => {
  await store.dispatch(sentMsgToWeb(type, data));
};

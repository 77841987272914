import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useSelector } from 'react-redux';
import SectionedProgressBar from '@app/src/Components/Common/SectionedProgressBar/SectionedProgressBar';
import { currentProgressSelector, initialLoadSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import '@app/src/Components/HolisticOnboarding/components/ProgressBarHeader/ProgressBarHeader.scss';

const ProgressBarHeader = ({ pathname }) => {
  const initialLoad = useSelector(initialLoadSelector);
  const currentProgress = useSelector((state) => currentProgressSelector(state, { pathname }));

  const { totalSections, progress } = currentProgress;

  return (
    <div>
      {initialLoad ? (
        <Skeleton variant='text' width='100%' height={20} animation='pulse' />
      ) : (
        <SectionedProgressBar totalSections={totalSections} currentSection={progress} className='mb-20' />
      )}
    </div>
  );
};

export default ProgressBarHeader;

import _ from 'lodash';
import moment from 'moment';
import {
  setHolisticOnboardingContentfulValues,
  setHolisticOnboardingDependents,
  setHolisticOnboardingDrive,
  setHolisticOnboardingEmail,
  setHolisticOnboardingError,
  setHolisticOnboardingFirstName,
  setHolisticOnboardingHome,
  setHolisticOnboardingIncomeType,
  setHolisticOnboardingInitialLoad,
  setHolisticOnboardingJob,
  setHolisticOnboardingLastName,
  setHolisticOnboardingLoading,
  setHolisticOnboardingMarried,
  setHolisticOnboardingMeal,
  setHolisticOnboardingPhone,
  setHolisticOnboardingRent,
  setHolisticOnboardingStartDate,
  setHolisticOnboardingTravel
} from '@app/src/actions/holisticOnboardingActions';
import { ONBOARDING_EXPERIMENTS_KEY } from '@app/src/constants/onboardingConstants';
import { isEmailUniqueSelector, isPhoneUniqueSelector } from '@app/src/selectors/authSelectors';
import { bankListSelector } from '@app/src/selectors/bankSelectors';
import {
  contentSelector,
  dependentsSelector,
  driveSelector,
  emailSelector,
  firstNameSelector,
  homeSelector,
  incomeTypeSelector,
  jobDurationSelector,
  jobsSelector,
  lastNameSelector,
  marriedSelector,
  mealSelector,
  phoneSelector,
  rentSelector,
  travelSelector
} from '@app/src/selectors/holisticOnboardingSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { generateClientDedupId, setUserWithObj, trackActivity } from '@app/src/services/analyticsService';
import {
  axiosWithErrorHandling,
  checkEmailUnique,
  checkPhoneUnique,
  setTokenUser
} from '@app/src/services/authService';
import { getContentfulEntries, getJobCategoryList } from '@app/src/services/contentfulService';
import {
  clearOnboardingState,
  consolidateContentfulValues,
  getExperimentProperties,
  getLocalStorage,
  getMarketingData,
  preserveProgress
} from '@app/src/utils/holisticOnboardingUtils';

export const initOnboarding = () => async (dispatch) => {
  try {
    dispatch(setHolisticOnboardingLoading(true));

    const [contentful] = await Promise.all([getContentfulEntries('onboarding'), dispatch(getJobCategoryList())]);

    const formattedValues = consolidateContentfulValues(contentful);

    dispatch(setHolisticOnboardingContentfulValues(formattedValues));

    const localStorageValues = JSON.parse(getLocalStorage('onboardingState'));

    const {
      firstname = '',
      lastname = '',
      email = '',
      phone = '',
      married = false,
      dependents = [],
      incomeType = [],
      drive = null,
      home = null,
      meal = null,
      travel = null,
      rent = '',
      jobs = [],
      jobDuration = ''
    } = localStorageValues || {};

    dispatch(setHolisticOnboardingFirstName(firstname));
    dispatch(setHolisticOnboardingLastName(lastname));
    dispatch(setHolisticOnboardingEmail(email));
    dispatch(setHolisticOnboardingPhone(phone));
    dispatch(setHolisticOnboardingMarried(married));
    dispatch(setHolisticOnboardingDependents(dependents));
    dispatch(setHolisticOnboardingIncomeType(incomeType));
    dispatch(setHolisticOnboardingDrive(drive));
    dispatch(setHolisticOnboardingHome(home));
    dispatch(setHolisticOnboardingMeal(meal));
    dispatch(setHolisticOnboardingTravel(travel));
    dispatch(setHolisticOnboardingRent(rent));
    dispatch(setHolisticOnboardingJob(jobs));
    dispatch(setHolisticOnboardingStartDate(jobDuration));
  } catch ({ message }) {
    throw new Error({ message });
  } finally {
    dispatch(setHolisticOnboardingLoading(false));
  }
};

export const initPage =
  ({ url }) =>
  async (dispatch, getState) => {
    const content = contentSelector(getState());

    if (_.isEmpty(content)) {
      return;
    }

    const analyticsEvent = _.get(content, [url, 'analyticsEvent']);
    const title = _.get(content, [url, 'title']);
    const isAuthenticated = !!getLocalStorage('KeeperToken');

    trackActivity('question: view', {
      flow: 'onboarding',
      type: 'holistic-onboarding',
      is_authenticated: isAuthenticated,
      question: analyticsEvent,
      title,
      client_dedup_id: generateClientDedupId()
    });

    dispatch(setHolisticOnboardingInitialLoad(false));
  };

export const onContinue =
  ({ pathname, push }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setHolisticOnboardingLoading(true));

      const content = contentSelector(getState());
      const nextUrl = _.get(content, [pathname, 'nextUrl']);

      const analyticsValues = {
        analyticsEvent: _.get(content, [pathname, 'analyticsEvent']),
        title: _.get(content, [pathname, 'title']),
        isAuthenticated: !!getLocalStorage('KeeperToken')
      };

      if (pathname === '/h/onboarding/signup') {
        const firstname = firstNameSelector(getState());
        const lastname = lastNameSelector(getState());
        const email = emailSelector(getState());

        await dispatch(checkEmailUnique(email));

        const isEmailUnique = isEmailUniqueSelector(getState());

        if (!isEmailUnique) {
          dispatch(setHolisticOnboardingError('Email already exists'));
          return;
        }

        preserveProgress({ firstname, lastname, email }, analyticsValues);
      } else if (pathname === '/h/onboarding/phone') {
        const phone = phoneSelector(getState());
        await dispatch(checkPhoneUnique(`1${phone}`));

        const isPhoneUnique = isPhoneUniqueSelector(getState());

        if (isPhoneUnique) {
          await dispatch(createAccount());
        } else {
          dispatch(setHolisticOnboardingError('Phone already exists'));
          return;
        }

        preserveProgress({ phone }, analyticsValues);
      } else if (pathname === '/h/onboarding/situations') {
        const selectedIncomeTypes = incomeTypeSelector(getState());

        const incomeType = _.isEmpty(selectedIncomeTypes) ? ['salaried', 'owner', 'contractor'] : selectedIncomeTypes;

        const values = {
          incomeType,
          married: marriedSelector(getState()),
          dependents: dependentsSelector(getState()),
          rent: rentSelector(getState()),
          homeowner: false,
          studentLoans: false
        };

        preserveProgress(values, analyticsValues);

        if (incomeType.includes('salaried') && incomeType.length === 1) {
          push('/h/onboarding/signup');
          return;
        }
      } else if (pathname === '/h/onboarding/job-select') {
        preserveProgress({ jobs: jobsSelector(getState()) }, analyticsValues);
      } else if (pathname === '/h/onboarding/job-duration') {
        preserveProgress({ jobDuration: jobDurationSelector(getState()) }, analyticsValues);
      } else if (pathname === '/h/onboarding/drive') {
        preserveProgress({ drive: driveSelector(getState()) }, analyticsValues);
      } else if (pathname === '/h/onboarding/home') {
        preserveProgress({ home: homeSelector(getState()) }, analyticsValues);
      } else if (pathname === '/h/onboarding/meal') {
        preserveProgress({ meal: mealSelector(getState()) }, analyticsValues);
      } else if (pathname === '/h/onboarding/travel') {
        preserveProgress({ travel: travelSelector(getState()) }, analyticsValues);
      } else if (pathname === '/h/onboarding/link') {
        const bankLinks = bankListSelector(getState());

        preserveProgress({ bankLinks }, analyticsValues);

        if (bankLinks.length === 0) {
          push('/h/onboarding/percentages');
          return;
        }
      } else if (pathname === '/h/onboarding/app-download') {
        dispatch(clearOnboardingState());
      }

      push(nextUrl);
    } catch (error) {
      dispatch(setHolisticOnboardingError(error));
    } finally {
      dispatch(setHolisticOnboardingLoading(false));
    }
  };

const createAccount = () => async (dispatch, getState) => {
  try {
    const localStorageValues = JSON.parse(getLocalStorage('onboardingState'));

    const phone = '1' + phoneSelector(getState());
    const firstname = _.get(localStorageValues, 'firstname', firstNameSelector(getState()));
    const lastname = _.get(localStorageValues, 'lastname', lastNameSelector(getState()));
    const email = _.get(localStorageValues, 'email', emailSelector(getState()));
    const married = _.get(localStorageValues, 'married', marriedSelector(getState()));
    const dependents = _.get(localStorageValues, 'dependents', dependentsSelector(getState()));
    const incomeType = _.get(localStorageValues, 'incomeType', incomeTypeSelector(getState()));
    const car = _.get(localStorageValues, 'drive', driveSelector(getState()));
    const home = _.get(localStorageValues, 'home', homeSelector(getState()));
    const meal = _.get(localStorageValues, 'meal', mealSelector(getState()));
    const travel = _.get(localStorageValues, 'travel', travelSelector(getState()));
    const dates = _.get(localStorageValues, 'jobDuration', jobDurationSelector(getState()));
    const jobs = _.get(localStorageValues, 'jobs', jobsSelector(getState()));

    let formattedJobDates;

    if (dates) {
      const [year, month] = dates.split(' - ');
      const formattedMonth = moment().month(month).format('MM');

      formattedJobDates = jobs.map(({ slug }) => ({ job: slug, dates: { year, month: formattedMonth } }));
    }
    const jobsArray = jobs.map(({ slug, name }) => slug ?? name);

    const signupObject = {
      phone,
      firstname,
      lastname,
      email,
      married,
      car,
      home,
      meal,
      travel,
      dependents,
      income_type: incomeType,
      ...(formattedJobDates && { job_dates: formattedJobDates }),
      jobs: jobsArray
    };

    const marketingData = getMarketingData();
    const experimentProperties = getExperimentProperties(_.get(getState(), ['onboarding', ONBOARDING_EXPERIMENTS_KEY]));

    const data = {
      ...signupObject,
      ...marketingData,
      experiments: experimentProperties
    };

    const res = await dispatch(
      axiosWithErrorHandling({
        method: 'post',
        url: `api/auth/signup`,
        data
      })
    );

    if (res.data.status === 'error') {
      throw new Error(res.data.message);
    }

    await dispatch(setTokenUser(res.data));

    const user = userSelector(getState());

    trackActivity('signup (frontend)', {
      userId: user.id,
      phone: user.phone,
      referrer: marketingData.referrer,
      client_dedup_id: marketingData.client_dedup_id
    });

    const userObj = {
      ...signupObject,
      ...experimentProperties,
      Affiliate_referral: marketingData.referrer
    };

    setUserWithObj(data.phone, userObj);
  } catch ({ message }) {
    trackActivity('onboarding: signup failed', { reason: message });
    dispatch(setHolisticOnboardingError(message));
  }
};

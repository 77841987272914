import React from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ExpenseUploadBanner from '@app/src/Components/Common/ExpenseUploadBanner/ExpenseUploadBanner';
import ExpenseReviewCategoryDropdown from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryDropdown';
import ExpenseReviewNumericFormat from '@app/src/Components/ExpenseReview/ExpenseReviewNumericFormat';
import { setManualExpenseAmount, setManualExpenseData } from '@app/src/actions/expenseReviewActions';
import CalendarIcon from '@app/src/assets/calendar.svg?react';
import { EXPENSE_UPLOAD_MANUAL_EXPENSE_BANNER_TITLE } from '@app/src/constants/constants';
import {
  categoryIdsToDisplayNamesSelector,
  hasValidManualExpenseDataSelector,
  isModalSubmittingSelector,
  manualExpenseAmountSelector,
  manualExpenseCategoryOptionsListSelector,
  manualExpenseDataSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { infoModalItemSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import { addManualExpenseInternal } from '@app/src/services/expenseReviewService';
import '@app/src/Components/ExpenseReview/ExpenseReviewModalAdd.scss';

const ExpenseReviewModalAdd = ({
  className,
  manualExpenseData,
  categoryOptionsList,
  categoryIdsToDisplayNames,
  hasValidManualExpenseData,
  manualExpenseAmount,
  isModalSubmitting,
  setManualExpenseData,
  setManualExpenseAmount,
  addManualExpenseInternal
}) => {
  const onSaveButtonClick = async () => {
    await addManualExpenseInternal();
  };

  const onMerchantChange = (value) => {
    setManualExpenseData({ ...manualExpenseData, description: value });
  };

  const onAmountChange = (value) => {
    if (!_.isNaN(value)) {
      setManualExpenseAmount(value);
    }
  };

  const onCategoryChange = (value) => {
    setManualExpenseData({ ...manualExpenseData, keeper_category_id: value });
  };

  const onDateChange = (value) => {
    if (value) {
      setManualExpenseData({ ...manualExpenseData, date: value.format('YYYY-MM-DD') });
    }
  };
  const onNotesChange = (value) => {
    setManualExpenseData({ ...manualExpenseData, note: value });
  };

  return (
    <div className={classNames('expense-review-modal-add', className)}>
      <div className='expense-review-modal-add-header'>Add an expense manually</div>
      <div className='expense-review-modal-add-line' />
      <Stack spacing={1}>
        <div>
          <InputLabel htmlFor='manual-expense-merchant'>Merchant name</InputLabel>
          <TextField
            id='manual-expense-merchant'
            name='manual-expense-merchant'
            onChange={(event) => onMerchantChange(_.get(event, ['target', 'value'], ''))}
            placeholder='e.g. Starbucks'
            value={_.get(manualExpenseData, 'description', '')}
            fullWidth
          />
        </div>
        <div>
          <InputLabel htmlFor='manual-expense-date-input'>Date</InputLabel>
          <DesktopDatePicker
            labelId={'manual-expense-date'}
            value={_.get(manualExpenseData, 'date') || null}
            maxDate={new Date()}
            onChange={(event) => onDateChange(event)}
            components={{ OpenPickerIcon: CalendarIcon }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                error={false}
                id='manual-expense-date-input'
                name='manual-expense-date-input'
                inputProps={{
                  ...params.inputProps,
                  placeholder: 'MM/DD/YYYY'
                }}
              />
            )}
          />
        </div>
        <div>
          <InputLabel htmlFor='manual-expense-amount'>Amount</InputLabel>
          <TextField
            value={manualExpenseAmount}
            onChange={(event) => onAmountChange(event.target.value)}
            placeholder='e.g. $5.00'
            name='manual-expense-amount'
            id='manual-expense-amount'
            fullWidth
            InputProps={{
              inputComponent: ExpenseReviewNumericFormat
            }}
          />
        </div>
        <div>
          <InputLabel htmlFor='manual-expense-dropdown-category'>Category</InputLabel>
          <ExpenseReviewCategoryDropdown
            labelId='manual-expense-dropdown-category'
            name='manual-expense-dropdown-category'
            disabled={isModalSubmitting}
            value={_.get(manualExpenseData, 'keeper_category_id')}
            displayName={categoryIdsToDisplayNames[_.get(manualExpenseData, 'keeper_category_id')] || 'Select category'}
            options={categoryOptionsList}
            onChange={(value) => onCategoryChange(_.get(value, ['value']))}
          />
        </div>
        <div>
          <InputLabel for='manual-expense-note'>Add a note (optional)</InputLabel>
          <TextField
            id='manual-expense-note'
            name='manual-expense-note'
            disabled={isModalSubmitting}
            value={_.get(manualExpenseData, 'note')}
            onChange={(event) => onNotesChange(_.get(event, ['target', 'value'], ''))}
            placeholder='e.g. Meeting with my client Zach'
            fullWidth
          />
        </div>
        <Button
          style={{ marginTop: '16px' }}
          variant='contained'
          onClick={onSaveButtonClick}
          disabled={isModalSubmitting || !hasValidManualExpenseData}
          color='secondary'
          fullWidth
        >
          Save
        </Button>
      </Stack>
      <ExpenseUploadBanner
        className='expense-review-modal-add-upload-banner'
        title={EXPENSE_UPLOAD_MANUAL_EXPENSE_BANNER_TITLE}
        origin='dashboard'
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  infoModalItem: infoModalItemSelector(state),
  categoryOptionsList: manualExpenseCategoryOptionsListSelector(state),
  categoryIdsToDisplayNames: categoryIdsToDisplayNamesSelector(state),
  isModalSubmitting: isModalSubmittingSelector(state),
  manualExpenseData: manualExpenseDataSelector(state),
  manualExpenseAmount: manualExpenseAmountSelector(state),
  hasValidManualExpenseData: hasValidManualExpenseDataSelector(state)
});

const mapDispatchToProps = {
  setManualExpenseData,
  setManualExpenseAmount,
  addManualExpenseInternal
};

const ConnectedExpenseReviewModalAdd = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewModalAdd);

export default ConnectedExpenseReviewModalAdd;

import React, { useRef } from 'react';
import _ from 'lodash';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { CloseCircle, SearchNormal1, TickCircle } from 'iconsax-react';
import Chip from '@app/src/Components/Common/Chip/Chip';
import IconWithFallback from '@app/src/Components/Common/IconWithFallback';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';
import { colorSuccess } from '@app/src/theme';
import '@app/src/Components/Common/SearchList/SearchList.scss';

const SearchList = ({
  items,
  listHeaderText,
  query,
  onAddItem,
  onRemoveItem,
  selectedItems,
  onSearch,
  onFocus = _.identity,
  style,
  iconFilter,
  searchPlaceholderText = 'Search',
  fallbackIcon,
  chipStyle = 'pill', // 'pill' or 'square'
  ...listProps
}) => {
  const sortedCheckedItems = _.chain(selectedItems).sortBy('pillOrder').value();
  const listRef = useRef();

  return (
    <div className='search-list' style={style}>
      <Collapse in={!_.isEmpty(sortedCheckedItems)} timeout={200} style={{ marginTop: 0 }} unmountOnExit>
        <div className='search-list-bar'>
          {sortedCheckedItems.map((item) => (
            <div key={item.id} className='search-list-bar-item-wrapper'>
              {chipStyle === 'square' ? (
                <TaxFlowPill
                  key={item.id}
                  text={item.name}
                  icon={
                    <IconWithFallback iconUrl={item.iconUrl} fallbackIcon={fallbackIcon} altText={`${item.id}-icon`} />
                  }
                  onRemove={() => onRemoveItem(item)}
                  removable={onRemoveItem != null}
                />
              ) : (
                <Chip
                  className='search-list-bar-chip'
                  icon={
                    <IconWithFallback
                      iconUrl={item.iconUrl}
                      fallbackIcon={fallbackIcon}
                      altText={`${item.id}-icon`}
                      filter={iconFilter}
                    />
                  }
                  item={item}
                  text={item.name}
                  hasCloseIcon
                  onCloseClick={() => onRemoveItem(item)}
                />
              )}
            </div>
          ))}
        </div>
      </Collapse>

      <TextField
        variant='outlined'
        margin='none'
        fullWidth
        value={query}
        placeholder={searchPlaceholderText}
        onChange={(e) => onSearch(e.target.value)}
        onFocus={onFocus}
        role='searchbox'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchNormal1 />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              {!_.isEmpty(query) && (
                <IconButton onClick={() => onSearch('')}>
                  <CloseCircle size={24} />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
        inputProps={{
          'data-testid': 'searchInput'
        }}
      />

      <List
        variant='scroll'
        className='search-list-list'
        style={{
          '--list-top': `${_.get(listRef, ['current', 'offsetTop'], 0)}px`
        }}
        ref={listRef}
        {...listProps}
      >
        {listHeaderText && <p className='search-list-list-header'>{listHeaderText}</p>}
        {_.map(items, (item) => {
          const { id, name, iconUrl, checked, showInList } = item;

          return (
            showInList && (
              <ListItemButton
                className='flex'
                key={id}
                selected={checked}
                divider
                onClick={() => (checked ? onRemoveItem(item) : onAddItem(item))}
                style={{ backgroundColor: 'transparent' }}
              >
                <ListItemIcon className='search-list-list-icon' sx={{ minWidth: '24px' }}>
                  <IconWithFallback
                    iconUrl={iconUrl}
                    fallbackIcon={fallbackIcon}
                    altText={`${name}-icon`}
                    filter={iconFilter}
                  />
                </ListItemIcon>

                <ListItemText primary={name} primaryTypographyProps={{ sx: { fontWeight: checked ? 500 : 400 } }} />
                {checked && <TickCircle color={colorSuccess} />}
              </ListItemButton>
            )
          );
        })}
      </List>
    </div>
  );
};

export default SearchList;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProgressBarWithTitle from '@app/src/Components/Common/ProgressBarWithTitle/ProgressBarWithTitle';
import { bankListSelector } from '@app/src/selectors/bankSelectors';

const RetroLoadingProgressBar = ({ pathname }) => {
  const [progress, setProgress] = useState(43);

  const skippedBankLink = useSelector(bankListSelector).length === 0;
  const isLoadingComplete = pathname === '/h/onboarding/app-download' || progress === 100;

  const title = skippedBankLink
    ? isLoadingComplete
      ? 'Account setup complete!'
      : 'Setting up your account'
    : isLoadingComplete
      ? 'Scan complete!'
      : 'Scanning for deductions';

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }

        return prevProgress + 1;
      });
    }, 1000);

    if (pathname === '/h/onboarding/app-download') {
      clearInterval(interval);
      setProgress(100);
    }

    return () => {
      clearInterval(interval);
    };
  }, [pathname]);

  return <ProgressBarWithTitle title={title} progress={progress} />;
};

export default RetroLoadingProgressBar;

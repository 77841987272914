import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import InfoDrawer from '@app/src/Components/HolisticOnboarding/components/InfoDrawer/InfoDrawer';
import LearnMoreButton from '@app/src/Components/HolisticOnboarding/components/LearnMoreButton/LearnMoreButton';
import OnboardingFooter from '@app/src/Components/HolisticOnboarding/components/OnboardingFooter';
import OnboardingSnackbar from '@app/src/Components/HolisticOnboarding/components/OnboardingSnackbar';
import PageTransition from '@app/src/Components/HolisticOnboarding/components/PageTransition';
import ProgressBarHeader from '@app/src/Components/HolisticOnboarding/components/ProgressBarHeader/ProgressBarHeader';
import QuestionBody from '@app/src/Components/HolisticOnboarding/components/QuestionBody';
import QuestionHeader from '@app/src/Components/HolisticOnboarding/components/QuestionHeader';
import RetroLoadingProgressBar from '@app/src/Components/HolisticOnboarding/components/RetroLoadingProgressBar/RetroLoadingProgressBar';
import { learnMoreSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import '@app/src/Components/HolisticOnboarding/containers/HolisticOnboardingLayout.scss';

const HolisticOnboardingLayout = ({ children }) => {
  const { pathname } = useLocation();

  const learnMoreInfo = useSelector((state) => learnMoreSelector(state, pathname));

  useEffect(() => {
    const container = document.querySelector('.h-onboarding-container');

    if (container) {
      container.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [pathname]);

  const showSegmentedProgressBar = ![
    '/h/onboarding/app-download',
    '/h/onboarding/loading-initial',
    '/h/onboarding/percentages',
    '/h/onboarding/app-download'
  ].includes(pathname);

  const showRetroLoadingProgressBar = ['/h/onboarding/percentages', '/h/onboarding/app-download'].includes(pathname);

  return (
    <div className='h-onboarding'>
      <div className='h-onboarding-container'>
        <div className='h-onboarding-content'>
          {showSegmentedProgressBar && <ProgressBarHeader pathname={pathname} />}
          {showRetroLoadingProgressBar && <RetroLoadingProgressBar pathname={pathname} />}
          <PageTransition pathname={pathname}>
            <div>
              <QuestionHeader />
              <LearnMoreButton learnMoreInfo={learnMoreInfo} />
              <QuestionBody>{children}</QuestionBody>
            </div>
          </PageTransition>
        </div>
      </div>
      <OnboardingSnackbar />
      <OnboardingFooter pathname={pathname} />
      <InfoDrawer learnMoreInfo={learnMoreInfo} />
    </div>
  );
};

export default HolisticOnboardingLayout;
